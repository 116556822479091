<template>
  <v-container
    v-if="settings"
    fluid
    style="height: 100%;"
    class="pa-0"
  >
    <TemplateQuestionnaireFormMobile
      ref="TemplateQuestionnaireForm"
      :template-data="template"
      :workbench="workbench"
      :case-data="actualCaseData"
      :options="{
        hasClose: true,
        inCase: true,
        inCaseModel: false,
        inTemplate: false
      }"
      @update-template-name="updateActualTemplateName"
      @save="saveTemplate"
      @addField="addNewQuestionnaireFieldInCase"
      @updateField="updateCaseQuestionnaireField"
      @change-field-order="updateQuestionnaireFieldsOrder"
      @deleteField="deleteFieldInCaseQuestionnaire"
      @closeTemplate="$router.back()"
      @updateQuestionnaire="updateQuestionnaire"
      @share="shareTemplate"
      @stop-sharing="stopSharingQuestionnaire"
    />
  </v-container>
</template>

<script>
import TemplateQuestionnaireFormMobile from './TemplateQuestionnaireFormMobile.vue'
import templateViewMixin from '../../Cases/CaseMixins/templateViewMixin'

export default {
  components: {
    TemplateQuestionnaireFormMobile
  },
  mixins: [templateViewMixin]
}
</script>

<style lang="scss" scoped>
  .template-view {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #fff;
    padding: 10px;
    z-index: 1;
  }
  .editor-container-outer {
    height: 100%;
  }
</style>
